@import './assets/custom-material.scss';
@import '~font-awesome/css/font-awesome.css';

:root{
    --color-red: #E0301E;
    --color-white: #FFFFFF;
    --color-black: #000000;
    --color-grey-dark: #2D2D2D;
    --color-grey-medium: #464646;
    --color-grey: #7D7D7D;
    --color-grey-light: #DEDEDE;
    --color-grey-light-m: #ADADAD; 
    --color-blue-light: #4dacf1;
    --color-green-dark: #2c8646;
    --color-orange: #fd7e14;
    --color-yellow : #ffc107;
    --color-green : #198754;
}


body{
    overflow-x: auto;
}
modal[ng-reflect-id="show-custom-modal"]{
    .modal{
        width: 40%;
        left: 33%;
        top: 20%;
    }
}


input[aria-label="Search"]{
    border: 1px solid var(--color-red);
}

.a-progress-text{
    margin-top: -2rem;
}

.a-progress{
    margin: 0!important;
}

.my-pagination{
    float: right;
    margin-right: 25px;
    margin-top: 15px;
    .ngx-pagination .current {
        background: var(--color-red)!important;
      }
} 

.a-modal.modal .modal-dialog .modal-content{
    height: 90vh!important;
}

@media screen and (min-width: 36rem){
    .a-modal.modal .modal-dialog.a-modal-xl {
        max-width: 80.625rem;
    }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-radio-button{
    display: block!important;
}

mat-dialog-container{
    padding: 0!important;
    overflow: hidden!important;
}

.mat-radio-button{
    margin-top: 10px!important;
}


.cdk-overlay-pane{
    max-width: 90vw!important;
}

.average-score-wrapper{
    .mat-slider-thumb-label {
        transform: rotate(45deg) !important;
        border-radius: 50% 50% 0 !important;
      }
    
    .mat-slider-thumb {
        transform: scale(0) !important;
    }
    
    .mat-slider-thumb-label-text {
        opacity: 1 !important;
    }
}

.tab-header-wrapper, .filter-wrapper{
    // .mat-tab-body-wrapper{
    //     height: 100vh!important;
    // }
    .mat-tab-body-content{
        overflow-x: hidden!important;
    }
    .mat-form-field-flex{
        border: 1px solid var(--color-red);
    }
    .mat-form-field-appearance-fill .mat-form-field-flex{
        background: var(--color-white);
    }
}

.add-user-wrapper{
    .mat-tab-body-wrapper{
        .mat-tab-body{
            .mat-tab-body-content{
                overflow: hidden!important;
            }
        }
    }
} 

.filter-wrapper .mat-form-field{
    margin: 0 10px;
    width: 150px;
}

.filter-wrapper{
    .mat-form-field-appearance-fill .mat-form-field-flex{
        background: var(--color-white);
    }
}

.results-progress-section {
    .mat-horizontal-content-container{
        display: none;
    }
}

.mat-simple-snackbar-action{
    color: green!important;
}
.add-user-wrapper{
    .mat-tab-body-wrapper{
        background-color: var(--color-white)!important;
        box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.2);
    }
}

.no-data-display {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
}

*{
    font-family: Arial, Helvetica, sans-serif;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
    background-color: #3F88C5!important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
    border-color: #3F88C5!important;
}

.mat-form-field-flex {
    border: 1px solid red!important;
    border-radius: 4px!important;
}

.mat-button-wrapper svg path{
    color: red!important;
}

/*.mat-tab-label{
    background: #E0301E!important; 
    color: white!important;
    border-top-left-radius: 10px!important;
    border-top-right-radius: 10px!important;
}*/
.mat-tab-label{
    background: #00000029!important; 
    color: #E0301E!important;
    border-top-left-radius: 10px!important;
    border-top-right-radius: 10px!important;
}
.mat-tab-label:hover{
    background: #2D2D2D!important;
    color: var(--color-white)!important;
}
.mat-tab-label-active{
    background: #E0301E!important; 
    color: white!important;
    border-top-left-radius: 10px!important;
    border-top-right-radius: 10px!important;
}
.mat-tab-label-active:hover:not(.active){
    background: #E0301E!important; 
    color: white!important;
    border-top-left-radius: 10px!important;
    border-top-right-radius: 10px!important;    
}

/*.mat-tab-label-active:hover:not(.active){
    background: #2D2D2D!important;
    color: var(--color-white)!important;
}*/

.no-border-textarea .mat-form-field-flex{
    border: none!important;
}

.esg-header-wrapper .row{
    margin-right: 0;
}

body {
    position: relative;
    margin: 0;
    padding-bottom: 4rem;
    min-height: 100%;
}


.main-wrapper{
    min-height: 75vh;
}